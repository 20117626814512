<template>
  <div class="skeleton-page">
    <h2 class="skeleton-page__title">Skeleton</h2>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton Default</h3>
      <div class="skeleton-page__content">
        <Skeleton />
      </div>
    </div>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton Props</h3>
      <ul class="skeleton-page__list">
        <li class="skeleton-page__item">
          <span>borderRadius</span> - скругление углов виде строки: '16px', "'0 16px 16px 16px'"
        </li>
      </ul>
    </div>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton Border Radius</h3>
      <div class="skeleton-page__content skeleton-page__content_round">
        <Skeleton :borderRadius="'16px'" />
      </div>
    </div>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton</h3>
      <div class="skeleton-page__container">
        <div class="skeleton-page__img">
          <Skeleton :borderRadius="'16px'" />
        </div>
        <div class="skeleton-page__img">
          <Skeleton :borderRadius="'16px'" />
        </div>
        <div class="skeleton-page__img">
          <Skeleton :borderRadius="'16px'" />
        </div>
      </div>
    </div>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton</h3>
      <div class="skeleton-page__container">
        <div class="skeleton-page__img">
          <Skeleton :borderRadius="'16px'" v-if="loader" />
          <img
            class="skeleton-page__pic"
            v-if="!loader"
            :src="require(`@/assets/img/imgAuth/1.png`)"
            width="200"
            height="200"
            alt="pic"
          />
        </div>
        <ul class="skeleton-page__list">
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loader">
              Организаторские способности, работа в команде
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loader" />
          </li>
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loader">
              Стратегическое мышление
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loader" />
          </li>
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loader">
              Технологические и технические компетенции
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loader" />
          </li>
        </ul>
      </div>
    </div>
    <div class="skeleton-page__wrap">
      <h3 class="skeleton-page__subtitle">Skeleton</h3>
      <div class="skeleton-page__container">
        <div class="skeleton-page__img">
          <Skeleton :borderRadius="'16px'" v-if="loaderList" />
          <img
            class="skeleton-page__pic"
            v-if="!loaderList"
            :src="require(`@/assets/img/imgAuth/1.png`)"
            width="200"
            height="200"
            alt="pic"
          />
        </div>
        <ul class="skeleton-page__list">
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loaderList">
              Организаторские способности, работа в команде
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loaderList" />
          </li>
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loaderList">
              Стратегическое мышление
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loaderList" />
          </li>
          <li class="skeleton-page__text">
            <p class="skeleton-page__item skeleton-page__item_example" v-if="!loaderList">
              Технологические и технические компетенции
            </p>
            <Skeleton :borderRadius="'8px'" v-if="loaderList" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
export default {
  name: "SkeletonPage",
  mixins: [],
  props: {},
  components: { Skeleton },
  data() {
    return {
      loader: true,
      loaderList: false
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.skeleton-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;
    font-family: "Roboto", "Arial", sans-serif;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
    font-family: "Roboto", "Arial", sans-serif;
  }

  &__content {
    width: 200px;
    height: 200px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
    font-family: "Roboto", "Arial", sans-serif;

    span {
      font-weight: bold;
    }

    &_example {
      font-size: 16px;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__img {
    width: 200px;
    height: 200px;
    border-radius: 16px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__pic {
    width: 200px;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
  }

  &__text {
    width: 100%;
    height: 30px;
    margin: 0 0 24px;
  }
}
</style>
